import { put, takeEvery, all } from 'redux-saga/effects'
import axios from 'axios'
import { updatePredictions, predictionError } from './predictionState'

function* handleGetPredictions(action) {
  try {
    yield put(predictionError(false))
    const getChannelByUrl = yield axios.get(
      `https://tubestats.io/api/channels/customURL/${action.payload}`
    )

    sessionStorage.setItem('channelId', getChannelByUrl.data.data.channelId)

    const prediction = yield axios.get(
      `https://tubestats.io/api/predictions/${getChannelByUrl.data.data.channelId}`
    )

    if (
      prediction.data.weeklyData != 'Not enough data.' &&
      prediction.data.monthlyData.length > 0
    ) {
      const dataSource = prediction.data.monthlyData.map(
        (prediction, index) => ({
          key: index,
          goalDate: prediction.date,
          subscriberPrediction: prediction.subPrediction,
          viewPrediction: prediction.viewsPrediction
        })
      )

      const weeklyData = prediction.data.weeklyData

      sessionStorage.setItem('dataSource', JSON.stringify(dataSource))
      sessionStorage.setItem('weeklyData', JSON.stringify(weeklyData))

      yield put(updatePredictions({ dataSource, weeklyData }))
    } else {
      yield put(predictionError(true))
    }
  } catch (error) {
    console.error('Error fetching popular channels:', error)
    return [] // Return an empty array in case of error
  }
}

export default function* rootSaga() {
  yield all([takeEvery('predictions/getPredictions', handleGetPredictions)])
}
