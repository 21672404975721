import { Helmet } from "react-helmet";
import { channelsTopicsList } from "../../api";
import { useNavigate } from "react-router-dom";

const ChannelCategories = ({}) => {
  const navigate = useNavigate();

  const handleSelectChange = (topic) => {
    sessionStorage.setItem("topicId", topic.value);
    sessionStorage.setItem("topicLabel", topic.label);

    // Create slug by converting to lowercase and replacing spaces with hyphens
    const topicSlug = topic.label
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, "") // Remove non-alphanumeric characters (except hyphens)
      .replace(/--+/g, "-"); // Remove multiple consecutive hyphens

    console.log(topicSlug); // For debugging purposes

    navigate(`/channel-categories/${topicSlug}`);
  };

  return (
    <div>
      <div className="text-center">
        <p className={`text-3xl font-extrabold`}>Channel Categories</p>
      </div>

      <div
        className={`flex flex-col w-full h-full items-center justify-center text-center mb-5`}
      >
        <Helmet>
          <title>Channel Categories | Tubestats</title>
        </Helmet>

        {/* Show all the regions from youtube */}
        <div className="mx-2 lg:mx-28 mt-9">
          <div className="flex flex-wrap gap-5 cursor-pointer">
            {channelsTopicsList.map((topic, index) => (
              <div
                key={index}
                className="flex items-center space-x-4 border rounded-2xl p-2 px-3"
                onClick={() => handleSelectChange(topic)}
              >
                <p className="font-medium">{topic.label}</p>
              </div>
            ))}
          </div>
        </div>

        {/* {loading && (
          <div className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md  flex justify-center items-center">
            <FaSpinner className="animate-spin " />
            <p className="ml-2">Loading...</p>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ChannelCategories;
