import { put, takeEvery, all } from 'redux-saga/effects'
import axios from 'axios'
import {
  setVideosData,
  setVideoStats,
  setChannelEngRates
} from './videoDetailState'
import getRandomKey from '../../utils/randomKeys.js'

// Old key
// const API_KEY = "AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw";
function* fetchLatestVideoStats() {
  try {
    const key = getRandomKey()
    const channelId = sessionStorage.getItem('channelId')
    if (!channelId) {
      console.error('ChannelId not found in sessionStorage')
      return
    }

    var channelResponse
    try {
      channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelId}&key=${key}`
      )
    } catch (error) {
      key = getRandomKey()
      channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelId}&key=${key}`
      )
    }
    const playlistId =
      channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads

    var playlistItemsResponse
    try {
      playlistItemsResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=10&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      playlistItemsResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=10&key=${key}`
      )
    }
    const videoIds = playlistItemsResponse.data.items.map(
      (item) => item.snippet.resourceId.videoId
    )

    const videoStatsPromises = videoIds.map((videoId) => {
      try {
        return axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${key}`
        )
      } catch (err) {
        key = getRandomKey()
        return axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${key}`
        )
      }
    })
    const videoStatsResponses = yield Promise.all(videoStatsPromises)

    const videosData = videoStatsResponses.map((response) => {
      const video = response.data.items[0]
      return {
        key: video.id,
        videoTitle: video.snippet.title,
        date: new Date(video.snippet.publishedAt).toLocaleDateString(),
        likes: video.statistics.likeCount,
        views: video.statistics.viewCount,
        comments: video.statistics.commentCount
          ? video.statistics.commentCount
          : null
      }
    })

    const totalLikes = videosData.reduce(
      (acc, video) => acc + parseInt(video.likes),
      0
    )
    const totalViews = videosData.reduce(
      (acc, video) => acc + parseInt(video.views),
      0
    )
    const totalComments = videosData.reduce(
      (acc, video) => (video.comments ? acc + parseInt(video.comments) : acc),
      0
    )

    const likeEngagementRate = ((totalLikes / totalViews) * 100).toFixed(2)
    const commentEngagementRate = ((totalComments / totalViews) * 100).toFixed(
      2
    )
    yield put(
      setChannelEngRates({
        likeEngagementRate,
        commentEngagementRate
      })
    )

    yield put(setVideosData(videosData))
  } catch (error) {
    console.error('Error fetching latest video statistics:', error)
  }
}

function* getVideoStat() {
  try {
    const key = getRandomKey()
    const videoId = sessionStorage.getItem('selectedVideo')
    var videoStatResponse
    try {
      videoStatResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics,contentDetails,topicDetails&id=${videoId}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      videoStatResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${key}`
      )
    }

    const videoStats = videoStatResponse.data.items[0]

    // Fetch top comments
    let topComments = []
    try {
      var commentsResponse
      try {
        commentsResponse = yield axios.get(
          `https://www.googleapis.com/youtube/v3/commentThreads?part=snippet&videoId=${videoId}&key=${key}&order=relevance&maxResults=10`
        )
      } catch (err) {
        key = getRandomKey()
        commentsResponse = yield axios.get(
          `https://www.googleapis.com/youtube/v3/commentThreads?part=snippet&videoId=${videoId}&key=${key}&order=relevance&maxResults=10`
        )
      }

      if (
        commentsResponse.data.items &&
        commentsResponse.data.items.length > 0
      ) {
        topComments = commentsResponse.data.items.map((comment) => ({
          textDisplay: comment.snippet.topLevelComment.snippet.textDisplay,
          authorDisplayName:
            comment.snippet.topLevelComment.snippet.authorDisplayName,
          authorProfileImageUrl:
            comment.snippet.topLevelComment.snippet.authorProfileImageUrl,
          likeCount: comment.snippet.topLevelComment.snippet.likeCount,
          date: new Date(
            comment.snippet.topLevelComment.snippet.publishedAt
          ).toLocaleDateString()
        }))
      }
    } catch (error) {
      // Check if the error is related to comments being disabled
      if (
        error.response?.data?.error?.message?.includes('has disabled comments')
      ) {
        console.warn('Comments are disabled for this video.')
      } else {
        console.error(
          'Error fetching comments:',
          error.response?.data || error.message
        )
      }
    }

    // Append top comments to the videoStats object
    videoStats.topComments = topComments

    yield put(setVideoStats(videoStats))
  } catch (error) {
    console.error('Error fetching video statistics:', error)
  }
}
export default function* rootSaga() {
  yield all([takeEvery('videoDetail/getVideosData', getVideoStat)])
  yield all([takeEvery('videoDetail/getVideoStats', fetchLatestVideoStats)])
  //   yield all([takeEvery("channel/startTracking", workStartTracking)]);
}
