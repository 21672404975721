import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FaSpinner } from 'react-icons/fa'
import { connect } from 'react-redux'
import {
  getRegions,
  getRegionVideos,
  startTracking
} from '../../redux/videos/videoState'
import { Helmet } from 'react-helmet'
import ReactCountryFlag from 'react-country-flag'
import { useDispatch } from 'react-redux'
const VideosRegion = ({
  getRegionVideos,
  videos,
  getRegions,
  regions,
  loading,
  pageToken,
  startTracking,
  lightMode
}) => {
  const regionId = sessionStorage.getItem('regionId')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [region, setRegion] = useState([])
  const [videoRecords, setVideoRecords] = useState([])
  const [lastSegment, setLastSegment] = useState(null)

  const bottomBoundaryRef = useRef(null)

  const handleSeeMore = (video) => {
    sessionStorage.setItem('channelId', video.channelId)
    sessionStorage.setItem('selectedVideo', video.videoId)

    startTracking()

    const newPath = location.pathname.includes(`/${regionId}/regionalVideos`)
      ? location.pathname.replace(`/${regionId}/regionalVideos`, '/') +
      `${video.channelCustomUrl}/videos/${video.videoId}`
      : `/${video.channelCustomUrl}/videos/${video.videoId}`

    console.log(newPath)
    navigate(newPath)
  }

  useEffect(() => {
    dispatch(getRegions())
    dispatch(getRegionVideos())
  }, [])

  useEffect(() => {
    if (videos && videos.length > 0) {
      setVideoRecords(videos)
    }
  }, [videos])

  const handleIntersection = (entries) => {
    const entry = entries[0]
    if (entry.isIntersecting && pageToken) {
      getRegionVideos(pageToken)
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    })

    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current)
    }

    return () => {
      if (bottomBoundaryRef.current) {
        observer.unobserve(bottomBoundaryRef.current)
      }
    }
  }, [pageToken])

  useEffect(() => {
    if (regions && regions.length > 0) {
      const regionDetails = regions.find((region) => region.id === regionId)

      setRegion(regionDetails)
    }
  }, [regions])

  const formatDate = (isoDate) => {
    const date = new Date(isoDate)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const filterVideos = async (filterBy) => {
    setVideoRecords([])

    let filteredVideos = []

    if (filterBy === 'likes') {
      filteredVideos = videos
        .filter((video) => video.statistics.likeCount > 0) // Filter videos with likes
        .sort((a, b) => b.statistics.likeCount - a.statistics.likeCount) // Sort by likes descending
    } else if (filterBy === 'views') {
      filteredVideos = videos
        .filter((video) => video.statistics.viewCount > 0) // Filter videos with views
        .sort((a, b) => b.statistics.viewCount - a.statistics.viewCount) // Sort by views descending
    } else if (filterBy === 'recent') {
      // Filter videos uploaded within the last 7 days
      const sevenDaysAgo = new Date()
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

      filteredVideos = videos
        .filter((video) => {
          const publishedDate = new Date(video.publishedAt)
          return publishedDate >= sevenDaysAgo // Compare with 7 days ago
        })
        .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)) // Sort by recent first
    } else if (filterBy === 'old') {
      // Filter videos uploaded more than 7 days ago
      const sevenDaysAgo = new Date()
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

      filteredVideos = videos
        .filter((video) => {
          const publishedDate = new Date(video.publishedAt)
          return publishedDate < sevenDaysAgo // Filter for older videos
        })
        .sort((a, b) => new Date(a.publishedAt) - new Date(b.publishedAt)) // Sort by oldest first
    }
    console.log('====================================')
    console.log('filteredVideos --->', filteredVideos)
    console.log('====================================')
    setLastSegment(filterBy)
    setVideoRecords(filteredVideos)
  }
  return (
    <div className='mb-5 mt-10 '>
      <div className='shadow-xl bg-gray-500 rounded-md md:h-56 mt-5 text-white'>
        <div className='flex flex-col items-center gap-4 w-[100%] pt-12'>
          <p
            className={`text-3xl lg:text-5xl font-semibold text-center text-white`}
          >
            Trending Videos
          </p>
        </div>

        <div className='w-[100%] flex justify-center pt-5'>
          <div className='flex flex-col items-center gap-4 w-[50%]'>
            {region && (
              <p className='text-3xl font-semibold flex items-center gap-1'>
                {region.title}
                <ReactCountryFlag
                  className='ml-3'
                  countryCode={`${region.id}`}
                  svg
                />
              </p>
            )}
          </div>
          <div className='flex flex-wrap justify-center md:justify-end space-x-4 mx-auto mt-4 md:mt-0'>
            <button
              type='button'
              onClick={() => filterVideos('likes')}
              className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${lastSegment === 'likes'
                  ? 'text-white bg-red-600'
                  : 'text-white bg-transparent'
                }`}
            >
              Filter By Likes
            </button>
            <button
              type='button'
              onClick={() => filterVideos('views')}
              className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${lastSegment === 'views'
                  ? 'text-white bg-red-600'
                  : 'text-white bg-transparent'
                }`}
            >
              Filter By Views
            </button>

            <button
              type='button'
              onClick={() => filterVideos('recent')}
              className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${lastSegment === 'recent'
                  ? 'text-white bg-red-600'
                  : 'text-white bg-transparent'
                }`}
            >
              Recent Uploads
            </button>

            <button
              type='button'
              onClick={() => filterVideos('old')}
              className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${lastSegment === 'old'
                  ? 'text-white bg-red-600'
                  : 'text-white bg-transparent'
                }`}
            >
              Older Uploads
            </button>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5'>
        {region && (
          <Helmet>
            <title> {region.title || 'Country'} | Tranding Videos</title>
          </Helmet>
        )}

        {videoRecords.map((video, index) => (
          <div
            key={index}
            className='w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md '
          >
            <div>
              <img
                src={video.thumbnails.medium.url}
                alt={video.title}
                className='w-full h-52 md:h-auto rounded-md'
              />
              <p className='py-1 font-semibold'>{video.title}</p>
              <div className='flex items-center font-light'>
                <p>Channel</p>
                <span className='mx-2'>|</span>
                <p
                  // onClick={() => searchClick(video.channelId)}
                  className=''
                >
                  {video.channelTitle}
                </p>
              </div>
              <div className='flex items-center font-light'>
                <p>Total Views</p>
                <span className='mx-2'>|</span>
                <p className=''>
                  {video.statistics.viewCount
                    ? video.statistics.viewCount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0}
                </p>
              </div>
              <div className='flex items-center font-light'>
                <p>Total Likes</p>
                <span className='mx-2'>|</span>
                <p className=''>
                  {video.statistics.likeCount
                    ? video.statistics.likeCount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0}
                </p>
              </div>
              <div className='flex items-center font-light'>
                <p>Upload Date</p>
                <span className='mx-2'>|</span>
                <p className=''>{video.publishedAt}</p>
              </div>
            </div>
            <button
              className={`w-full bg-[#FC0707] hover:bg-gray-500  mb-2 font-bold py-2 mt-3 px-4 rounded ${lightMode ? 'text-white' : ''
                }`}
              onClick={() => {
                handleSeeMore(video)
              }}
            >
              See Stats
            </button>
          </div>
        ))}

        {loading && (
          <div className='w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md  flex justify-center items-center'>
            <FaSpinner className='animate-spin ' />
            <p className='ml-2'>Loading...</p>
          </div>
        )}
        {/* <div ref={bottomBoundaryRef}></div> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state.videos,
    lightMode: state.app.lightMode
  }
}

const mapDispatchToProps = {
  getRegionVideos: getRegionVideos,
  getRegions: getRegions,
  startTracking: startTracking
}
export default connect(mapStateToProps, mapDispatchToProps)(VideosRegion)
