import { put, takeEvery, all } from 'redux-saga/effects'
import axios from 'axios'
import {
  setSearchResults,
  setSelectedChannel,
  setSelectedRegion,
  setSelectedVideo,
  updatePopularChannels,
  updateRegions
} from './homeState'

// New client key
import getRandomKey from '../../utils/randomKeys.js'

// old client key
// const API_KEY = "AIzaSyDZftdreNiPBIcJcLJSW24j_ZiQbgX2kJI";

function* handleSearchSubmit(action) {
  try {
    var key = getRandomKey()
    const { searchQuery, searchType } = action.payload
    if (searchType === 'Channels') {
      var response
      try {
        response = yield axios.get(
          'https://www.googleapis.com/youtube/v3/search',
          {
            params: {
              part: 'snippet',
              q: searchQuery,
              type: 'channel',
              maxResults: 5,
              key: key
            }
          }
        )
      } catch (err) {
        key = getRandomKey()
        response = yield axios.get(
          'https://www.googleapis.com/youtube/v3/search',
          {
            params: {
              part: 'snippet',
              q: searchQuery,
              type: 'channel',
              maxResults: 5,
              key: key
            }
          }
        )
      }
      yield put(setSearchResults(response.data.items))
    } else {
      const params = new URLSearchParams(new URL(searchQuery).search)
      const videoId = params.get('v')

      var response
      try {
        response = yield axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${key}`
        )
      } catch (err) {
        key = getRandomKey()
        response = yield axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${key}`
        )
      }

      var channelResponse
      try {
        channelResponse = yield axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${response.data.items[0].snippet.channelId}&key=${key}`
        )
      } catch (err) {
        key = getRandomKey()
        channelResponse = yield axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${response.data.items[0].snippet.channelId}&key=${key}`
        )
      }
      sessionStorage.setItem(
        'channelId',
        response.data.items[0].snippet.channelId
      )
      sessionStorage.setItem('selectedVideo', videoId)
      const customUrl = channelResponse.data.items[0].snippet.customUrl

      yield put(setSelectedVideo({ customUrl, videoId }))
      //   yield put(navigate(`/${customUrl}/videos/${videoId}`));
    }
  } catch (error) {
    console.error('Error searching:', error)
  }
}

function* handleSearchClick(action) {
  try {
    var key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${action.payload}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${action.payload}&key=${key}`
      )
    }

    sessionStorage.setItem('channelId', action.payload)
    yield put(setSelectedChannel(response.data.items[0]))
    // yield put(
    //   navigate(
    //     `/${
    //       response.data.items[0].snippet.customUrl ||
    //       response.data.items[0].snippet.title
    //     }/analytics`
    //   )
    // );
  } catch (error) {
    console.error('Error fetching popular channels:', error)
    return [] // Return an empty array in case of error
  }
}

function* handleRegionClick(action) {
  try {
    var key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/i18nRegions?part=snippet&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/i18nRegions?part=snippet&key=${key}`
      )
    }

    sessionStorage.setItem('regionId', action.payload)

    const regionDetails = response.data.items.find(
      (region) => region.id === action.payload
    )

    yield put(setSelectedRegion(regionDetails))
  } catch (error) {
    console.error('Error fetching regions:', error)
    return [] // Return an empty array in case of error
  }
}

function* handlePopularChannels(action) {
  try {
    var key = getRandomKey()
    const response = yield axios.get(
      `https://tubestats.io/api/popular_channels`
    )
    const channelInfoArray = []
    // Loop through the array of channelIds
    for (const channelId of response.data.channelIds) {
      var channelResponse
      try {
        channelResponse = yield axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${channelId}&key=${key}`
        )
      } catch (err) {
        key = getRandomKey()
        channelResponse = yield axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${channelId}&key=${key}`
        )
      }
      // Extract the relevant information from the response
      const channelData = channelResponse.data.items[0].snippet

      // Push the channel information into the array
      channelInfoArray.push({
        channelId: channelId,
        title: channelData.title,
        description: channelData.description,
        thumbnails: channelData.thumbnails
      })
    }

    yield put(updatePopularChannels(channelInfoArray))
  } catch (error) {
    console.error('Error fetching popular channels:', error)
    return [] // Return an empty array in case of error
  }
}

function* handleRegions(action) {
  try {
    var key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/i18nRegions?part=snippet&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/i18nRegions?part=snippet&key=${key}`
      )
    }
    const regionInfoArray = []

    // Loop through the array of channelIds
    for (const region of response?.data?.items) {
      // Push the channel information into the array
      regionInfoArray.push({
        id: region.id,
        title: region?.snippet?.name
      })
    }

    yield put(updateRegions(regionInfoArray))
  } catch (error) {
    console.error('Error fetching regions:', error)
    return [] // Return an empty array in case of error
  }
}

export default function* rootSaga() {
  yield all([takeEvery('home/getSearchResults', handleSearchSubmit)])
  yield all([takeEvery('home/searchClick', handleSearchClick)])
  yield all([takeEvery('home/regionClick', handleRegionClick)])
  yield all([takeEvery('home/getPopularChannels', handlePopularChannels)])
  yield all([takeEvery('home/getRegions', handleRegions)])
}
