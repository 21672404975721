import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import getRandomeKey from "../../utils/randomKeys";
import { SearchOutlined } from "@ant-design/icons";
import { countries } from "../../constants/channelsConstant";
import { connect } from "react-redux";
const AllChannels = ({ lightMode }) => {
  const [location, setLocation] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("");
  const [limit, setLimit] = useState(5);
  const [minViews, setMinViews] = useState();
  const [maxViews, setMaxViews] = useState();
  const [minSubs, setMinSubs] = useState();
  const [maxSubs, setMaxSubs] = useState();
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPageinationData] = useState({
    total: 0,
    page: 0,
    limit: 0,
    totalPages: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const fetchChannels = (filters, pageToken = "") => {
    const key = getRandomeKey();
    setLoading(true);
    if (!channels.length) {
      setChannels([]);
    }

    const params = {
      title: searchQuery,
      category: category,
      minViews: minViews,
      maxViews: maxViews,
      minSubscribers: minSubs,
      maxSubscribers: maxSubs,
      limit: limit,
    };

    axios
      .get("http://127.0.0.1:3001/api/channels/", {
        params: params,
      })
      .then((response) => {
        console.log("====================================");
        console.log("response.data --->", response.data);
        console.log("====================================");
        setChannels(response.data.data);
        setPageinationData(response.data.pagination);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching channels:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchChannels(searchQuery);
  }, []);

  const handleNextPage = () => {};

  const handlePrevPage = () => {};
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    fetchChannels(searchQuery);
  }, [
    currentPage,
    searchQuery,
    minSubs,
    maxSubs,
    minViews,
    maxViews,
    category,
    limit,
  ]);

  console.log("====================================");
  console.log("channels --->", channels);
  console.log("====================================");
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full mx-2 lg:mx-28 mt-9">
        <p className="flex flex-row justify-center font-bold mb-5 text-lg">
          All Channels
        </p>
        <div className="flex flex-col sm:flex-row items-center justify-evenly mt-16 w-full">
          <div
            className={`relative flex items-center w-full max-w-lg sm:mb-0 border-gradient bg-white animate-border border rounded-xl`}
          >
            <input
              type="search"
              name="search"
              placeholder={"Search ..."}
              className={`bg-white h-12 px-5 pr-10 text-lg focus:outline-none flex-grow text-black`}
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <button
              type="button"
              className="absolute text-2xl right-0 top-0 mt-3 mr-2 flex items-center"
              onClick={() => fetchChannels(searchQuery)}
            >
              <SearchOutlined />
            </button>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col items-center justify-center mt-16 w-full">
          <div className="flex flex-wrap gap-4 mt-5">
            <div className="flex flex-col gap-y-1">
              <lable className={` ${lightMode ? "text-black" : "text-white"}`}>
                Minimum Views
              </lable>
              <input
                type="number"
                placeholder="Min Views"
                value={minViews}
                onChange={(e) => setMinViews(e.target.value)}
                className="border rounded-lg px-4 py-2 text-black"
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <lable className={` ${lightMode ? "text-black" : "text-white"}`}>
                Maximum Views
              </lable>
              <input
                type="number"
                placeholder="Max Views"
                value={maxViews}
                onChange={(e) => setMaxViews(e.target.value)}
                className="border rounded-lg px-4 py-2 text-black"
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <lable className={` ${lightMode ? "text-black" : "text-white"}`}>
                Minimum Subscribers
              </lable>
              <input
                type="number"
                placeholder="Min Subscribers"
                value={minSubs}
                onChange={(e) => setMinSubs(e.target.value)}
                className="border rounded-lg px-4 py-2 text-black"
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <lable className={` ${lightMode ? "text-black" : "text-white"}`}>
                Maximum Subscribers
              </lable>
              <input
                type="number"
                placeholder="Max Subscribers"
                value={maxSubs}
                onChange={(e) => setMaxSubs(e.target.value)}
                className="border rounded-lg px-4 py-2 text-black"
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <lable className={` ${lightMode ? "text-black" : "text-white"}`}>
                Limit Per Page
              </lable>
              <input
                type="number"
                placeholder="Limit"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="border rounded-lg px-4 py-2 text-black"
              />
            </div>
          </div>

          {/* Pagination */}
          <div className="flex justify-between my-5">
            <button
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50 text-black"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage <= 1 || loading}
            >
              Previous
            </button>
            <p className="font-medium my-auto mx-2">Page {currentPage}</p>
            <button
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50 text-black"
              onClick={() => setCurrentPage((prev) => prev + 1)}
              disabled={paginationData?.totalPages <= currentPage || loading}
            >
              Next
            </button>
          </div>
          <div className="flex flex-wrap gap-5 cursor-pointer">
            {loading && <p>Loading...</p>}
            {channels?.map((channel) => (
              <div
                key={channel.channelId}
                className="flex items-center space-x-4 border rounded-2xl p-2 px-3"
                onClick={() => {}}
              >
                {channel?.snippet?.thumbnails ? (
                  <img
                    key={channel.snippet.channelId}
                    src={channel.snippet.thumbnails.default.url}
                    alt={channel.snippet.title}
                    className="w-12 h-12 rounded-full"
                  />
                ) : null}
                <p className="font-medium">{channel.channelTitle}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lightMode: state.app.lightMode,
  };
};
export default connect(mapStateToProps, {})(AllChannels);
