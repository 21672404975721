import { call, put, takeEvery, all } from 'redux-saga/effects'
import axios from 'axios'
import { setData, setSimilarChannels } from './similarState'

import getRandomKey from '../../utils/randomKeys.js'

function* calculateSimilarityScores(channel, targetResponse) {
  var key = getRandomKey()
  var channelDetails
  try {
    channelDetails = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics,topicDetails,brandingSettings,status&id=${channel.id.channelId}&key=${key}`
    )
  } catch (error) {
    key = getRandomKey()
    channelDetails = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics,topicDetails,brandingSettings,status&id=${channel.id.channelId}&key=${key}`
    )
  }

  const relativeAudienceSize = calculateRelativeAudienceSize(
    targetResponse.data.items[0],
    channelDetails.data.items[0]
  )

  const targetTopicIds = targetResponse.data.items[0]?.topicDetails?.topicIds
  const channelTopicIds = channelDetails.data.items[0]?.topicDetails?.topicIds
  let categorySimilarity = null
  if (targetTopicIds && channelTopicIds) {
    // Calculate category similarity
    categorySimilarity = calculateCategorySimilarity(
      targetTopicIds,
      channelTopicIds
    )
  }

  // Convert scores to percentages
  const relativeAudienceSizePercent = relativeAudienceSize * 100
  const categorySimilarityPercent = categorySimilarity
    ? categorySimilarity * 100
    : null

  // Calculate total score as the sum of audience overlap and category similarity
  const totalScore =
    relativeAudienceSizePercent + (categorySimilarityPercent || 0)

  return {
    channel: channelDetails.data.items[0],
    score: totalScore
  }
}

function calculateRelativeAudienceSize(channel1, channel2) {
  const subscriberCount1 = parseInt(channel1.statistics.subscriberCount)
  const subscriberCount2 = parseInt(channel2.statistics.subscriberCount)

  const minSubscribers = Math.min(subscriberCount1, subscriberCount2)
  const maxSubscribers = Math.max(subscriberCount1, subscriberCount2)

  if (maxSubscribers === 0) return 0

  // Calculate the ratio of smaller subscriber count to larger one
  const ratio = minSubscribers / maxSubscribers

  // Map the ratio to a score between 0 and 1
  // A higher score indicates closer subscriber counts
  const score = 1 - Math.abs(ratio - 0.5) * 2

  return score
}

function calculateCategorySimilarity(topicIds1, topicIds2) {
  // Convert the arrays of topic IDs to sets for efficient comparison
  const topicSet1 = new Set(topicIds1)
  const topicSet2 = new Set(topicIds2)

  // Calculate the intersection of the two sets (common topic IDs)
  const intersection = new Set(
    [...topicSet1].filter((topicId) => topicSet2.has(topicId))
  )

  // Calculate the Jaccard Index
  const similarity =
    intersection.size / (topicSet1.size + topicSet2.size - intersection.size)
  return similarity
}

function* fetchChannelDetails(action) {
  try {
    var key = getRandomKey()
    const storedChannelId = sessionStorage.getItem('channelId')
    if (!storedChannelId) {
      console.error('Channel ID not found in local storage')
      return
    }

    var targetResponse
    try {
      targetResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics,topicDetails,brandingSettings,status&id=${storedChannelId}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      targetResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics,topicDetails,brandingSettings,status&id=${storedChannelId}&key=${key}`
      )
    }

    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&type=channel&q=${targetResponse.data.items[0].snippet.title}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&type=channel&q=${targetResponse.data.items[0].snippet.title}&key=${key}`
      )
    }

    const channelsWithScores = yield all(
      response.data.items.map((channel) =>
        call(calculateSimilarityScores, channel, targetResponse)
      )
    )

    const sortedChannels = channelsWithScores.sort((a, b) => b.score - a.score)
    const topSimilarChannels = sortedChannels.slice(0, 10)
    const maxDifference = 0.999 // Adjust this value as per your preference

    const filteredChannels = topSimilarChannels.filter((channel) => {
      const targetSubscriberCount = parseInt(
        targetResponse.data.items[0].statistics.subscriberCount
      )
      const channelSubscriberCount = parseInt(
        channel.channel.statistics.subscriberCount
      )

      // Check if the channel's subscriber count is significantly smaller than the target channel
      const difference = Math.abs(
        targetSubscriberCount - channelSubscriberCount
      )
      const percentageDifference = difference / targetSubscriberCount

      // Check if the channel's title is different from the target channel's title
      const isDifferentTitle =
        channel.channel.snippet.title.toLowerCase() !==
        targetResponse.data.items[0].snippet.title.toLowerCase()
      if (percentageDifference <= maxDifference && isDifferentTitle) {
        return channel
      }
    })

    const data = filteredChannels.map((channel) => {
      // Extracting relevant data from the channel object
      const { title, thumbnails, statistics, customUrl } =
        channel.channel.snippet
      const { subscriberCount, viewCount, videoCount } =
        channel.channel.statistics
      const similarityScore = channel.score.toFixed(2) // Adjust precision as needed

      return {
        key: channel.channel.id, // Unique key for each row
        title: title,
        image: (
          <img src={thumbnails.default.url} alt={title} style={{ width: 50 }} />
        ),
        subscriberCount: parseInt(subscriberCount).toLocaleString(),
        viewCount: parseInt(viewCount).toLocaleString(),
        videoCount: parseInt(videoCount).toLocaleString(),
        similarityScore: similarityScore,
        customUrl: customUrl
      }
    })

    yield put(setData(data))
    yield put(
      setSimilarChannels(topSimilarChannels.map((item) => item.channel))
    )
  } catch (error) {
    console.error('Error fetching channel details:', error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery('similar/getSimilarChannels', fetchChannelDetails)])
}
