import { put, takeEvery, all } from 'redux-saga/effects'
import axios from 'axios'
import { getSearchSuccess, updateSearchClick } from './topbarState.js'

import getRandomKey from '../../utils/randomKeys.js'

function* workGetSearch(action) {
  try {
    const key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        'https://www.googleapis.com/youtube/v3/search',
        {
          params: {
            part: 'snippet',
            q: action.payload,
            type: 'channel',
            maxResults: 5,
            key: key // Replace with your YouTube API key
          }
        }
      )
    } catch (error) {
      key = getRandomKey()
      response = yield axios.get(
        'https://www.googleapis.com/youtube/v3/search',
        {
          params: {
            part: 'snippet',
            q: action.payload,
            type: 'channel',
            maxResults: 5,
            key: key // Replace with your YouTube API key
          }
        }
      )
    }
    yield put(getSearchSuccess(response.data.items))
  } catch (error) {
    console.error('Error searching:', error)
  }
}

function* workSearchClick(action) {
  try {
    const key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${action.payload}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${action.payload}&key=${key}`
      )
    }
    sessionStorage.setItem('channelId', action.payload)

    // window.location.reload();
    yield put(updateSearchClick(response.data.items[0].snippet))
  } catch (error) {
    console.error('Error fetching popular channels:', error)
    return [] // Return an empty array in case of error
  }
}

export default function* rootSaga() {
  yield all([takeEvery('topbar/getSearchResults', workGetSearch)])
  yield all([takeEvery('topbar/searchClick', workSearchClick)])
  //   yield all([takeEvery("channel/startTracking", workStartTracking)]);
}
