import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getSearchResults,
  setSearchResults,
  searchClick,
  getPopularChannels,
} from "../../redux/home/homeState";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import "./home.css";
import { Select } from "antd";
import { Helmet } from "react-helmet";

const Home = ({
  searchResults,
  getSearchResults,
  setSearchResults,
  searchClick,
  selectedChannel,
  selectedVideo,
  lightMode,
  getPopularChannels,
  popularChannels,
}) => {
  const [channels, setChannels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("Channels");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleDropdownChange = (type) => {
    setSearchType(type);
    setSearchQuery(""); // Clear search query when dropdown changes
    setDropdownOpen(false); // Close dropdown after selection
  };

  useEffect(() => {
    getPopularChannels();

  }, []);

  useEffect(() => {
    if (searchQuery) {
      getSearchResults({
        searchQuery: searchQuery,
        searchType: searchType,
      });
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.customUrl) {
      navigate(`/${selectedVideo.customUrl}/videos/${selectedVideo.videoId}`);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (selectedChannel && selectedChannel.snippet) {
      navigate(
        `/${
          selectedChannel.snippet.customUrl || selectedChannel.snippet.title
        }/analytics`
      );
    }
  }, [selectedChannel]);

  return (
    <div
      className={`flex flex-col mt-28 w-full h-full items-center justify-center text-center mb-5`}
    >
      <Helmet>
        <title>YouTube Channel Insights & Analytics | Tubestats</title>
      </Helmet>
      <div className="text-center lg:text-left">
        <p
          className={`text-3xl lg:text-6xl font-extrabold ${
            lightMode ? "text-gray-800" : ""
          }`}
        >
          Explore YouTube Insights
        </p>
        <p className="text-lg lg:text-xl text-center font-medium text-gray-400 mt-6 lg:mx-auto lg:max-w-2xl">
          Search YouTube channels or paste video URLs to see real-time stats and
          analytics. Dive into subscriber counts, engagement metrics, and video
          views or likes.
        </p>
      </div>
      <div className="flex !flex-row w-full flex-col sm:flex-row items-center justify-center mt-16 w-full">
        <div
          className={`relative flex items-center w-full sm:w-2/3 max-w-lg sm:mb-0 border-gradient bg-white animate-border border `}
        >
          <input
            type="search"
            name="search"
            placeholder={
              searchType === "Channels"
                ? "Search for Channels"
                : "Copy Video URL"
            }
            className={`bg-white h-12 px-5 pr-10 text-lg focus:outline-none flex-grow text-black`}
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <button
            type="button"
            className="absolute text-2xl right-0 top-0 mt-3 mr-3 flex items-center"
            // onClick={handleSearchSubmit}
          >
            <SearchOutlined />
          </button>
        </div>
        <div className="sm:mt-0  custom-dropdown">
          <Select
            defaultValue="Channels"
            style={{ width: "100%" }}
            onChange={handleDropdownChange}
            options={[
              { value: "Channels", label: "Channels" },
              { value: "Videos", label: "Videos" },
            ]}
          />
        </div>
      </div>

      <div className="mt-1 bg-white rounded-2xl shadow-md  w-full max-w-xl mx-auto text-black">
        {searchResults.length > 0 && (
          <ul>
            {searchResults.map((channel) => (
              <li
                key={channel.id.channelId}
                onClick={() => searchClick(channel.snippet.channelId)}
                className={`search-result-item rounded-2xl px-4 py-2 cursor-pointer hover:text-white hover:bg-red-600 flex items-center `}
              >
                <img
                  src={channel.snippet.thumbnails.default.url}
                  alt={channel.snippet.title}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <div className="text-sm">{channel.snippet.title}</div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="mx-2 lg:mx-28 mt-9">
        <p className="font-bold mb-5 text-lg">Popular Channels</p>
        <div className="flex flex-wrap gap-5 cursor-pointer">
          {popularChannels.map((channel) => (
            <div
              key={channel.channelId}
              className="flex items-center space-x-4 border rounded-2xl p-2 px-3"
              onClick={() => searchClick(channel.channelId)}
            >
              <img
                src={channel.thumbnails.default.url}
                alt={channel.title}
                className="w-12 h-12 rounded-full"
              />
              <p className="font-medium">{channel.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    ...state.home,
    lightMode: state.app.lightMode,
  };
}

const mapDispatchToProps = {
  getSearchResults: getSearchResults,
  setSearchResults: setSearchResults,
  searchClick: searchClick,
  getPopularChannels: getPopularChannels,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
