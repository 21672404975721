import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { connect } from "react-redux";
import { getVideos } from "../../redux/videos/videoState";
import { Helmet } from "react-helmet";

const Videos = ({
  getVideos,
  videos,
  loading,
  pageToken,
  lightMode,
  channelDetail,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const bottomBoundaryRef = useRef(null);

  const handleSeeMore = (video) => {
    sessionStorage.setItem("selectedVideo", video.videoId);
    const newPath = location.pathname.endsWith("/")
      ? `${location.pathname}${video.videoId}`
      : `${location.pathname}/${video.videoId}`;
    navigate(newPath);
  };

  useEffect(() => {
    getVideos();
  }, []);

  const handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting && pageToken) {
      getVideos(pageToken);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });

    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current);
    }

    return () => {
      if (bottomBoundaryRef.current) {
        observer.unobserve(bottomBoundaryRef.current);
      }
    };
  }, [pageToken]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
      {channelDetail.snippet && (
        <Helmet>
          <title>Videos | {channelDetail.snippet.customUrl}</title>
        </Helmet>
      )}
      {videos.map((video, index) => (
        <div
          key={index}
          className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md "
        >
          <div>
            <img
              src={video.thumbnails.medium.url}
              alt={video.title}
              className="w-full h-52 md:h-auto rounded-md"
            />
            <p className="py-1 font-semibold">{video.title}</p>
            <div className="flex items-center font-light">
              <p>Total Views</p>
              <span className="mx-2">|</span>
              <p className="">
                {video.statistics.viewCount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </div>
            <div className="flex items-center font-light">
              <p>Total Likes</p>
              <span className="mx-2">|</span>
              <p className="">
                {video.statistics.likeCount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </div>
          </div>
          <button
            className={`w-full bg-[#FC0707] hover:bg-gray-500  mb-2 font-bold py-2 mt-3 px-4 rounded ${
              lightMode ? "text-white" : ""
            }`}
            onClick={() => {
              handleSeeMore(video);
            }}
          >
            See Stats
          </button>
        </div>
      ))}
      {loading && (
        <div className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md  flex justify-center items-center">
          <FaSpinner className="animate-spin " />
          <p className="ml-2">Loading...</p>
        </div>
      )}
      <div ref={bottomBoundaryRef}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.videos,
    lightMode: state.app.lightMode,
  };
};

const mapDispatchToProps = {
  getVideos: getVideos,
};
export default connect(mapStateToProps, mapDispatchToProps)(Videos);
