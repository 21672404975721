import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getSearchResults,
  getSearchSuccess,
  searchClick
} from '../../redux/topbar/topbarState'
import { setMode } from '../../redux/app/appState'
import { CiLight } from 'react-icons/ci'
import { IoMoonOutline } from 'react-icons/io5'
import { RxHamburgerMenu } from 'react-icons/rx'
import {
  setSelectedChannel,
  setSelectedRegion,
  getRegions,
  regionClick
} from '../../redux/home/homeState'
import { channelsTopicsList } from '../../api'
import ReactCountryFlag from 'react-country-flag'

const Topbar = ({
  getSearchResults,
  search_results,
  getSearchSuccess,
  searchClick,
  search_click,
  setMode,
  regionClick,
  selectedRegion,
  lightMode,
  getRegions,
  regions
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTopic, setSearchTopic] = useState('')
  const navigate = useNavigate()
  const [isLightMode, setIsLightMode] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null)

  useEffect(() => {
    const storedMode = sessionStorage.getItem('lightMode')
    if (storedMode === 'false') {
      setMode(false)
    }
  }, [])

  console.log('====================================')
  console.log('regions --->', regions)
  console.log('====================================')

  const handleChannelCategoryChange = (topic) => {
    sessionStorage.setItem('topicId', topic.value)
    sessionStorage.setItem('topicLabel', topic.label)

    // Create slug by converting to lowercase and replacing spaces with hyphens
    const topicSlug = topic.label
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters (except hyphens)
      .replace(/--+/g, '-') // Remove multiple consecutive hyphens

    console.log(topicSlug) // For debugging purposes

    navigate(`/channel-categories/${topicSlug}`)
  }

  const toggleMode = () => {
    setMode(!lightMode)
  }

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.search-container')) {
        getSearchSuccess([]) // Hide search results if clicked outside of the search container
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (searchQuery) {
      getSearchResults(searchQuery)
    } else {
      getSearchSuccess([])
    }
  }, [searchQuery])

  useEffect(() => {
    if ((search_click && search_click.customUrl) || search_click.title) {
      navigate(`/${search_click.customUrl || search_click.title}/analytics`)
    }
  }, [search_click])

  const handleClick = () => {
    // sessionStorage.removeItem("channelId");
    // sessionStorage.removeItem("videoId");
    // sessionStorage.removeItem("regionId");
    setSelectedChannel([])
    setSelectedRegion([])
    navigate('/')
  }

  useEffect(() => {
    if (activeMenu == 'countries') {
      getRegions()
    }
  }, [activeMenu])

  useEffect(() => {
    if (selectedRegion && selectedRegion.snippet) {
      const countrySlug = selectedRegion.snippet.name
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters (except hyphens)
        .replace(/--+/g, '-') // Remove multiple consecutive hyphens

      navigate(`/trending-videos/${countrySlug}`)
    }
  }, [selectedRegion])

  return (
    <div className='relative z-[99999999]'>
      <div className='navbar py-5 sm:py-10 px-5 sm:px-20 flex flex-col sm:flex-row items-center justify-between'>
        {/* Logo and Hamburger Menu */}
        <div className='flex justify-between w-full'>
          <div
            className='logo-text text-2xl sm:text-3xl font-bold mb-2 sm:mb-0 cursor-pointer'
            onClick={handleClick}
          >
            Tubestats
          </div>
          {/* Hamburger Menu */}
          <RxHamburgerMenu
            className='block sm:hidden'
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
        {/* Search Input and Dark/Light Icons */}

        <div
          className={`flex items-center ${
            menuOpen ? 'flex sm:hidden' : 'hidden sm:flex'
          }`}
        >
          <ul className='flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse'>
            <li className='flex items-center'>
              <a
                href='/all-channels'
                className='w-full py-2 px-3 whitespace-nowrap font-medium hover:underline hover:text-blue-600 dark:hover:text-blue-500 hover:cursor-pointer'
              >
                All Channels
              </a>
            </li>
            <li>
              <button
                id='mega-menu-full-cta-image-button'
                data-collapse-toggle='mega-menu-full-image-dropdown'
                onClick={() =>
                  setActiveMenu(activeMenu === 'countries' ? null : 'countries')
                }
                className='flex items-center justify-between w-full py-2 px-3 font-medium whitespace-nowrap hover:underline hover:text-blue-600 dark:hover:text-blue-500 hover:cursor-pointer'
              >
                Countries
                <svg
                  className='w-2.5 h-2.5 ms-3'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 10 6'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='m1 1 4 4 4-4'
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                id='mega-menu-full-cta-image-button'
                data-collapse-toggle='mega-menu-full-image-dropdown'
                onClick={() =>
                  setActiveMenu(
                    activeMenu === 'categories' ? null : 'categories'
                  )
                }
                className='flex items-center justify-between w-full py-2 px-3 font-medium whitespace-nowrap hover:underline hover:text-blue-600 dark:hover:text-blue-500 hover:cursor-pointer'
              >
                Channel Categories
                <svg
                  className='w-2.5 h-2.5 ms-3'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 10 6'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='m1 1 4 4 4-4'
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>

        <div
          className={`flex items-center sm:ml-auto ${
            menuOpen ? 'flex sm:hidden' : 'hidden sm:flex'
          }`}
        >
          {/* Search Input */}
          <div className='search-container relative'>
            <input
              type='search'
              name='search'
              placeholder='Search'
              className='search-input w-full sm:w-80 text-black px-4 py-2 rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500 border'
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            {/* Optionally, you can add a search icon here */}
            <div className='search-results absolute text-black top-full left-0 w-full bg-white mt-2 sm:mt-0 sm:w-80 rounded-lg shadow-md overflow-hidden search-container z-10'>
              {search_results.length > 0 && (
                <ul>
                  {search_results.map((channel) => (
                    <li
                      key={channel.id.channelId}
                      onClick={() => searchClick(channel.snippet.channelId)}
                      className='search-result-item rounded-2xl px-4 py-2 cursor-pointer hover:text-white hover:bg-red-600 flex items-center'
                    >
                      <img
                        src={channel.snippet.thumbnails.default.url}
                        alt={channel.snippet.title}
                        className='w-8 h-8 rounded-full mr-2'
                      />
                      <div className='text-sm'>{channel.snippet.title}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {/* Dark/Light Icons */}
          <div className='sm:hidden justify-end px-5 border rounded-2xl ml-3 '>
            {lightMode ? (
              <IoMoonOutline
                onClick={toggleMode}
                className='text-gray-500 text-3xl'
              />
            ) : (
              <CiLight onClick={toggleMode} className='text-white text-3xl' />
            )}
          </div>
        </div>
        <div className='flex items-center hidden sm:block'>
          <div
            className={`flex justify-end px-5 border rounded-2xl ml-3  ${
              lightMode ? 'hover:bg-gray-100' : 'hover:bg-gray-800'
            }`}
          >
            {lightMode ? (
              <IoMoonOutline
                onClick={toggleMode}
                className='text-gray-500 text-3xl hover:text-gray-700 cursor-pointer'
              />
            ) : (
              <CiLight
                onClick={toggleMode}
                className='text-white text-3xl hover:text-gray-300 cursor-pointer'
              />
            )}
          </div>
        </div>
      </div>

      {/* This is a Megamenu code for regions */}
      {activeMenu === 'countries' && (
        <div
          id='mega-menu-full-cta-dropdown'
          className={`mt-1 absolute z-[999999] ${
            lightMode ? 'bg-white' : 'bg-[#191919]'
          } w-full shadow-[1px_1px_0_rgba(0,0,0,0.1)] py-[30px] `}
        >
          <div className='grid max-w-screen-xl px-4 py-5 mx-auto text-sm md:grid-cols-3 md:px-6'>
            <ul
              className='space-y-4 sm:mb-4 md:mb-0'
              aria-labelledby='mega-menu-full-cta-button'
            >
              {regions.slice(0, 5).map((region, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setActiveMenu(null)
                    regionClick(region.id)
                  }}
                  className='flex items-center space-x-4 p-2 cursor-pointer hover:underline hover:text-blue-600 dark:hover:text-blue-500'
                >
                  {/* Display the flag */}
                  <ReactCountryFlag
                    className='w-12 h-12 rounded-full'
                    countryCode={region.id} // Ensure region.id matches the country code format
                    svg
                  />

                  {/* Display the region name */}
                  <p className='font-medium'>{region.title}</p>
                </li>
              ))}
            </ul>
            <ul className='hidden mb-4 space-y-4 md:mb-0 sm:block'>
              {regions.slice(5, 10).map((region, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setActiveMenu(null)
                    regionClick(region.id)
                  }}
                  className='flex items-center space-x-4 p-2 cursor-pointer hover:underline hover:text-blue-600 dark:hover:text-blue-500'
                >
                  {/* Display the flag */}
                  <ReactCountryFlag
                    className='w-12 h-12 rounded-full'
                    countryCode={region.id} // Ensure region.id matches the country code format
                    svg
                  />

                  {/* Display the region name */}
                  <p className='font-medium'>{region.title}</p>
                </li>
              ))}
            </ul>
            <div className='mt-4 md:mt-0'>
              <h2 className='mb-2 font-semibold'>
                Explore Trending Videos by Country
              </h2>
              <p className='mb-2'>
                Discover the latest trending videos from around the world.
                Browse by country to see what’s popular in different regions and
                stay up to date with global trends.
              </p>
              <a
                href='/trending-videos-by-country'
                className='inline-flex items-center text-sm font-medium hover:underline hover:text-blue-600 dark:hover:text-blue-700'
              >
                View All Trending Videos
                <svg
                  className='w-3 h-3 ms-2 rtl:rotate-180'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 10'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M1 5h12m0 0L9 1m4 4L9 9'
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      )}

      {/* This is a Megamenu code for regions */}
      {activeMenu === 'categories' && (
        <div
          id='mega-menu-full-cta-dropdown'
          className={`mt-1 absolute z-[999999] ${
            lightMode ? 'bg-white' : 'bg-[#191919]'
          } w-full shadow-[1px_1px_0_rgba(0,0,0,0.1)] py-[30px] `}
        >
          <div className='grid max-w-screen-xl px-4 py-5 mx-auto text-sm md:grid-cols-3 md:px-6'>
            <ul
              className='space-y-4 sm:mb-4 md:mb-0'
              aria-labelledby='mega-menu-full-cta-button'
            >
              {channelsTopicsList.slice(0, 5).map((topic, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handleChannelCategoryChange(topic)
                    setActiveMenu(null)
                  }}
                  className='hover:underline hover:text-blue-600 dark:hover:text-blue-500 hover:cursor-pointer'
                >
                  {topic.label}
                </li>
              ))}
            </ul>
            <ul className='hidden mb-4 space-y-4 md:mb-0 sm:block'>
              {channelsTopicsList.slice(5, 10).map((topic, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handleChannelCategoryChange(topic)
                    setActiveMenu(null)
                  }}
                  className='hover:underline hover:text-blue-600 dark:hover:text-blue-500 hover:cursor-pointer'
                >
                  {topic.label}
                </li>
              ))}
            </ul>
            <div className='mt-4 md:mt-0'>
              <h2 className='mb-2 font-semibold'>
                Explore YouTube Channel Categories
              </h2>
              <p className='mb-2'>
                Discover a variety of channel categories tailored to your
                interests. Browse our curated categories to find the content you
                love.
              </p>
              <a
                href='/channel-categories'
                className='inline-flex items-center text-sm font-medium hover:underline hover:text-blue-600 dark:hover:text-blue-700'
              >
                View All Categories
                <svg
                  className='w-3 h-3 ms-2 rtl:rotate-180'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 10'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M1 5h12m0 0L9 1m4 4L9 9'
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    ...state.home,
    search_results: state.topbar.search_results,
    search_click: state.topbar.search_click,
    lightMode: state.app.lightMode
  }
}

const mapDispatchToProps = {
  getSearchResults: getSearchResults,
  getSearchSuccess: getSearchSuccess,
  searchClick: searchClick,
  setMode: setMode,
  setSelectedChannel: setSelectedChannel,
  setSelectedRegion: setSelectedRegion,
  regionClick: regionClick,
  getRegions: getRegions
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar)
