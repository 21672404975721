const keys = [
  'AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw',
  'AIzaSyDIIPUdnlIKUSRTodiO8-Rz_4QliTz07rQ',
  'AIzaSyCI-pxjzO7GVdTo5pFojZnXzYKV_p4u5Vo',
  'AIzaSyDDemim9r_AO7v1irr60MO088K2dEX-Is4',
  'AIzaSyBnuQs4oMKONx42BxQVVAwrzonpeSMzGtw',
  'AIzaSyDEjBSAf2HfPTWer7rhc0UZthYzz4u8ZYw',
  'AIzaSyAS2PzYDeJsevDzCChpN1MrVPBn6jS6mkg'
]

export default keys
