import { put, takeEvery, all } from 'redux-saga/effects'
import axios from 'axios'
import {
  setVideos,
  setLoading,
  setPageToken,
  updateRegions
} from './videoState'
import getRandomKey from '../../utils/randomKeys.js'
// Client's old API key

// New client's api key
// const API_KEY = "AIzaSyB7MaPvK4aqh9xXe1I4RHm9HMTx-_gBTzM";

function* fetchVideos(action) {
  try {
    var key = getRandomKey()
    yield put(setLoading(true))
    yield put(setVideos([]))
    const channelId = sessionStorage.getItem('channelId')
    var channelResponse
    try {
      channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails&id=${channelId}&key=${key}`
      )
      // check if the request was success or not if not then generate another random key and hit again
    } catch (err) {
      key = getRandomKey()
      channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails&id=${channelId}&key=${key}`
      )
    }
    const playlistId =
      channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads

    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&pageToken=${
          action.payload || ''
        }&order=date&type=video&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&pageToken=${
          action.payload || ''
        }&order=date&type=video&key=${key}`
      )
    }

    const newVideos = response.data.items.map((item) => ({
      videoId: item.snippet.resourceId.videoId,
      title: item.snippet.title,
      description: item.snippet.description,
      thumbnails: item.snippet.thumbnails,
      publishedAt: item.snippet.publishedAt,
      statistics: null // Placeholder for statistics
    }))

    const videoIds = newVideos.map((video) => video.videoId)

    var statsResponse
    try {
      statsResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoIds.join(
          ','
        )}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      statsResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoIds.join(
          ','
        )}&key=${key}`
      )
    }

    newVideos.forEach((video, index) => {
      video.statistics = statsResponse.data.items[index].statistics
    })

    yield put(setVideos(newVideos))

    if (
      response.data.nextPageToken &&
      response.data.pageInfo.totalResults > newVideos.length
    ) {
      yield put(setPageToken(response.data.nextPageToken))
    } else {
      yield put(setPageToken(null))
    }
  } finally {
    yield put(setLoading(false))
  }
}

const formatDate = (isoDate) => {
  const date = new Date(isoDate)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

function* fetchRegionVideos(action) {
  try {
    var key = getRandomKey()
    yield put(setLoading(true))
    yield put(setVideos([]))
    const regionId = sessionStorage.getItem('regionId')

    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&chart=mostPopular&regionCode=${regionId}&maxResults=50&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&chart=mostPopular&regionCode=${regionId}&maxResults=50&key=${key}`
      )
    }
    // Extract unique channel IDs
    const channelIds = [
      ...new Set(response.data.items.map((item) => item.snippet.channelId))
    ]

    var channelResponse
    try {
      channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails&id=${channelIds.join(
          ','
        )}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails&id=${channelIds.join(
          ','
        )}&key=${key}`
      )
    }

    // Create a map of channel details by channelId
    const channelDataMap = channelResponse.data.items.reduce((acc, channel) => {
      acc[channel.id] = channel.snippet.customUrl
      return acc
    }, {})

    const newVideos = response.data.items.map((item) => ({
      videoId: item.id,
      channelId: item.snippet.channelId,
      channelTitle: item.snippet.channelTitle,
      channelCustomUrl: channelDataMap[item.snippet.channelId],
      title: item.snippet.title,
      description: item.snippet.description,
      thumbnails: item.snippet.thumbnails,
      publishedAt: formatDate(item.snippet.publishedAt),
      statistics: item.statistics // Placeholder for statistics
    }))

    yield put(setVideos(newVideos))

    if (
      response.data.nextPageToken &&
      response.data.pageInfo.totalResults > newVideos.length
    ) {
      yield put(setPageToken(response.data.nextPageToken))
    } else {
      yield put(setPageToken(null))
    }
  } catch (error) {
    console.error('Error fetching videos:', error)
  } finally {
    yield put(setLoading(false))
  }
}

function* handleRegions(action) {
  try {
    var key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/i18nRegions?part=snippet&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/i18nRegions?part=snippet&key=${key}`
      )
    }
    const regionInfoArray = []

    // Loop through the array of channelIds
    for (const region of response?.data?.items) {
      // Push the channel information into the array
      regionInfoArray.push({
        id: region.id,
        title: region?.snippet?.name
      })
    }

    yield put(updateRegions(regionInfoArray))
  } catch (error) {
    console.error('Error fetching regions:', error)
    return [] // Return an empty array in case of error
  }
}

function* workStartTracking(action) {
  const id = sessionStorage.getItem('channelId')
  try {
    var key = getRandomKey()
    var response
    try {
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${id}&key=${key}`
      )
    } catch (err) {
      key = getRandomKey()
      response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${id}&key=${key}`
      )
    }

    yield axios.post(`https://tubestats.io/api/track`, {
      channelId: id,
      customURL: response.data.items[0].snippet.customUrl,
      channelTitle: response.data.items[0].snippet.title,
      initialViews: response.data.items[0].statistics.viewCount,
      initialSubscribers: response.data.items[0].statistics.subscriberCount,
      thumbnails: response.data.items[0].snippet.thumbnails 
    })
    // window.location.reload();
    return
  } catch (error) {
    console.log(error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery('videos/getVideos', fetchVideos)])
  yield all([takeEvery('videos/getRegionVideos', fetchRegionVideos)])
  yield all([takeEvery('videos/getRegions', handleRegions)])
  yield all([takeEvery('videos/startTracking', workStartTracking)])
}
