import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ReactCountryFlag from "react-country-flag";
import axios from "axios";
import { searchClick } from "../../redux/home/homeState";

const TopicsChannels = ({
  searchClick,
  selectedChannel,
  lightMode,
}) => {
  const topicId = sessionStorage.getItem("topicId");
  const topicName = sessionStorage.getItem("topicLabel");
  const [loading, setLoading] = useState(false);

  const [channels, setChannels] = useState([]);

  const API_KEY = "AIzaSyB7MaPvK4aqh9xXe1I4RHm9HMTx-_gBTzM";
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedChannel && selectedChannel.snippet) {
      navigate(
        `/${
          selectedChannel.snippet.customUrl || selectedChannel.snippet.title
        }/analytics`
      );
    }
  }, [selectedChannel]);

  const getChannelsByTopic = async () => {
    try {
      if(topicId){
        setLoading(true);
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/search",
          {
            params: {
              part: "snippet",
              type: "channel",
              topicId: topicId,
              maxResults: 50,
              key: `${API_KEY}`,
            },
          }
        );

        setChannels(response.data.items);
        setLoading(false);
      }
     } catch (error) {
      setLoading(false);
       console.error("Error searching:", error);
     }
  }
  const bottomBoundaryRef = useRef(null);
  
  useEffect(() => {
    getChannelsByTopic();
  }, [topicId]);
  
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  
  return (
    <div className="mb-5 mt-10 ">
      <div className="shadow-xl bg-gray-500 rounded-md md:h-56 mt-5 text-white">
        <div className="flex flex-col items-center gap-4 w-[100%] pt-12">
          <p
            className={`text-3xl lg:text-5xl font-semibold text-center text-white`}
          >
            Channels ({topicName ? topicName : 'Not Available'})
          </p>
        </div>

        {/* <div className="w-[100%] flex justify-center pt-5">
          <div className="flex flex-col items-center gap-4 w-[50%]">
            {region && (
              <p className="text-3xl font-semibold flex items-center gap-1">
                {region.title}
                <ReactCountryFlag
                  className="ml-3"
                  countryCode={`${region.id}`}
                  svg
                />
              </p>
            )}
          </div>
          <div className="flex items-center justify-center gap-4 w-[50%]">
            <label htmlFor="date" className="text-lg font-medium text-white">
              Select Date
            </label>
            <input
              id="date"
              className="form-input bg-white text-black rounded-lg border-2 border-gray-300 p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-[30%]"
              name="date"
              type="date"
              value={date}
              onChange={handleDateChange}
            />
          </div>
        </div> */}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
        {topicName && (
          <Helmet>
            <title> {topicName || "Topic"} | All Channels</title>
          </Helmet>
        )}

        {channels.map((channel, index) => (
          <div
            key={index}
            className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md "
          >
            <div>
              <img
                src={channel.snippet.thumbnails.medium.url}
                alt={channel.snippet.title}
                className="w-full h-52 md:h-auto rounded-md"
              />
              <p className="py-1 font-semibold">{channel.snippet.title}</p>
              
              <div className="flex items-center font-light">
                <p>Date</p>
                <span className="mx-2">|</span>
                <p className="">{formatDate(channel.snippet.publishedAt)}</p>
              </div>
            </div>
            <button
              className={`w-full bg-[#FC0707] hover:bg-gray-500  mb-2 font-bold py-2 mt-3 px-4 rounded ${
                lightMode ? "text-white" : ""
              }`}
              onClick={() => searchClick(channel.snippet.channelId)}
            >
              See Channel Details
            </button>
          </div>
        ))}

        {loading && (
          <div className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md  flex justify-center items-center">
            <FaSpinner className="animate-spin " />
            <p className="ml-2">Loading...</p>
          </div>
        )}
        {/* <div ref={bottomBoundaryRef}></div> */}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    ...state.home,
    lightMode: state.app.lightMode,
  };
}

const mapDispatchToProps = {
  searchClick: searchClick,
};
export default connect(mapStateToProps, mapDispatchToProps) (TopicsChannels);
