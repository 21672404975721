import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import App from './containers/App/App'
import Home from './containers/Home/home'
import ChAnalytics from './containers/Channel/channelAnalytics'
import AllChannels from './containers/Channel/allChannels'
import Predictions from './containers/Channel/Predictions'
import { connect } from 'react-redux'
import VideosRegion from './containers/videos/videosRegion'
import TopicsChannels from './containers/topicChannels/topicsChannels'
import ChannelCategories from './containers/topicChannels/ChannelCategories'
import Countries from './containers/countries/countries'

const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Router basename=''>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='' element={<Home />} />
          <Route path='/all-channels' element={<AllChannels />} />
          <Route path=':channelTitle/analytics' element={<ChAnalytics />} />
          <Route path=':channelTitle/videos' element={<ChAnalytics />} />
          <Route path=':channelTitle/about' element={<ChAnalytics />} />
          <Route path=':channelTitle/similar' element={<ChAnalytics />} />
          <Route path=':channelTitle/predictions' element={<ChAnalytics />} />
          <Route
            path=':channelTitle/videos/:videoId'
            element={<ChAnalytics />}
          />
          <Route path='trending-videos-by-country' element={<Countries />} />
          <Route path='trending-videos/:country' element={<VideosRegion />} />
          <Route path='channel-categories' element={<ChannelCategories />} />
          <Route
            path='channel-categories/:categoryId'
            element={<TopicsChannels />}
          />
        </Route>
      </Routes>
    </Router>
  )
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(PublicRoutes)
