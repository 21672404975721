import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  regionClick,
  getRegions,
} from "../../redux/home/homeState";
import { useNavigate } from "react-router-dom";
import "../Home/home.css";
import { Helmet } from "react-helmet";
import ReactCountryFlag from "react-country-flag";

const Countries = ({
  regionClick,
  selectedRegion,
  lightMode,
  getRegions,
  regions,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    if (selectedRegion && selectedRegion.snippet) {
      const countrySlug = selectedRegion.snippet.name
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/[^\w\-]+/g, "") // Remove non-alphanumeric characters (except hyphens)
        .replace(/--+/g, "-"); // Remove multiple consecutive hyphens
      
      navigate(`/trending-videos/${countrySlug}`);
    }
  }, [selectedRegion]);

  return (
    <div
      className={`flex flex-col mt-28 w-full h-full items-center justify-center text-center mb-5`}
    >
      <Helmet>
        <title>Countries | Tubestats</title>
      </Helmet>
      <div className="text-center lg:text-left">
        <p
          className={`text-3xl lg:text-6xl font-extrabold ${
            lightMode ? "text-gray-800" : ""
          }`}
        >
          Countries
        </p>
      </div>

      {/* Show all the regions from youtube */}
      <div className="mx-2 lg:mx-28 mt-9">
        <div className="flex flex-wrap gap-5 cursor-pointer">
          {regions.map((region) => (
            <div
              key={region.id}
              className="flex items-center space-x-4 border rounded-2xl p-2 px-3"
              onClick={() => regionClick(region.id)}
            >
              <ReactCountryFlag
                className="w-12 h-12 rounded-full"
                countryCode={`${region.id}`}
                svg
              />
              <p className="font-medium">{region.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    ...state.home,
    lightMode: state.app.lightMode,
  };
}

const mapDispatchToProps = {
  regionClick: regionClick,
  getRegions: getRegions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Countries);
